import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { m, AnimatePresence, MotionConfig } from "framer-motion"

import Wrapper from "../Wrapper/Wrapper"
import Button from "../Button/Button"
import useViewport from "../../hooks/useViewport"

import Next from "../../images/icons/Next.svg"

export const fragment = graphql`
  fragment TestimonialsSection on WpPage_FrontpageAcf_Content_TestimonialsSection {
    description
    fieldGroupName
    link {
      target
      title
      url
    }
    testimonial {
      testimonialText
      authorPosition
      authorName
      backgroundImage {
        gatsbyImage(width: 1900, quality: 100, placeholder: BLURRED, layout: FULL_WIDTH)
        altText
        title
      }
      companyLogo {
        localFile {
          publicURL
        }
        height
        width
      }
    }
    title
  }
`

const Testimonials = ({ data: { description, link, testimonial, title } }) => {
  const { isTablet, isMobile } = useViewport()
  const [activeSlide, setActiveSlide] = React.useState(0)
  const activeTestimonial = testimonial[activeSlide]

  const nextSlide = () =>
    activeSlide === testimonial.length - 1 ? setActiveSlide(0) : setActiveSlide(a => a + 1)

  const getNextSlide = () => (activeSlide === testimonial.length - 1 ? 0 : activeSlide + 1)

  return (
    <MotionConfig transition={transition}>
      <section className="relative overflow-hidden bg-white pt-14 text-monochromeInk md:pt-24 lg:pt-32">
        <Wrapper className="lg:flex">
          <h2
            className={`text-3xl font-semibold   md:text-4xl lg:mr-20 lg:w-1/2 lg:flex-1 lg:text-5xl `}>
            {title}
          </h2>

          <div className="lg:flex-1">
            {description && (
              <p
                className={`mt-6  text-sm text-monochromeInk-light md:text-base lg:mt-0 lg:text-lg`}>
                {description}
              </p>
            )}
            {link && (
              <Button className="mt-6 text-primaryBlue" href={link.url} target={link.target}>
                {link.title}
              </Button>
            )}
          </div>
        </Wrapper>
        <div className="relative mt-9 bg-monochromeInk-10 md:mt-12 md:min-h-[340px] lg:mt-16 lg:bg-none">
          <div className="p-5  md:absolute md:bottom-0 md:right-0 md:z-10  md:h-full md:max-w-sm md:bg-monochromeInk-10 lg:max-h-[400px] lg:-translate-x-1/4 ">
            <AnimatePresence exitBeforeEnter>
              <m.div
                key={activeSlide}
                variants={isMobile ? testimonialAnimation : desktopTestmonialAnimation}
                initial="initial"
                animate="animate"
                exit="exit"
                transition={{
                  duration: 0.3,
                }}
                className="z-20 flex h-full flex-col justify-between">
                <img
                  src={activeTestimonial.companyLogo.localFile.publicURL}
                  width={activeTestimonial.companyLogo.width}
                  height={activeTestimonial.companyLogo.height}
                  loading="lazy"
                />
                <p className="mt-14 max-w-[85%] font-medium text-monochromeSky-lightest">
                  {activeTestimonial.testimonialText}
                </p>
                <div className="mt-14 border-l-2  pl-3 text-monochromeSky-lightest">
                  <p className="text-lg font-semibold ">{activeTestimonial.authorName}</p>
                  <p className="mt-1 text-sm font-medium">{activeTestimonial.authorPosition}</p>
                </div>
              </m.div>
            </AnimatePresence>
          </div>

          <AnimatePresence>
            <m.div
              key={activeSlide}
              variants={slider}
              initial="initial"
              animate="animate"
              exit="exit"
              className=" flex w-screen ">
              <div className="relative flex-grow">
                <GatsbyImage
                  image={getImage(activeTestimonial.backgroundImage.gatsbyImage)}
                  className="hidden h-[620px] flex-grow md:block"
                  objectFit="cover"
                  alt={"123"}
                />
              </div>
            </m.div>
          </AnimatePresence>
          <button
            className="4 absolute inset-y-0 right-0 z-20 flex h-full w-1/6 min-w-[48px] max-w-[5%] items-center justify-center   to-transparent sm:bg-none md:bg-gradient-to-l md:from-monochromeInk-10 lg:pr-10"
            onClick={nextSlide}>
            <Next className="relative h-6  w-6 stroke-2 text-white lg:h-16 lg:w-16 lg:stroke-1" />
          </button>
        </div>
      </section>
    </MotionConfig>
  )
}

const desktopTestmonialAnimation = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
}

const testimonialAnimation = {
  initial: {
    opacity: 0,
    x: "20px",
  },
  animate: {
    opacity: 1,
    x: 0,
  },
  exit: {
    opacity: 0,
    x: "-20px",
  },
}

const slider = {
  initial: {
    position: "absolute",
    x: "100%",
  },
  animate: {
    position: "relative",
    x: 0,
  },
  exit: {
    position: "absolute",
    x: "-100%",
  },
}

const transition = {
  duration: 0.3,
  type: "linear",
}

export default Testimonials
