import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Title from "../Title";
import Wrapper from "../Wrapper/Wrapper";
import Button from "../Button/Button";

export const fragment = graphql`
  fragment SignUpSection on WpPage_FrontpageAcf_Content_SignUpSection {
    description
    fieldGroupName
    title
    link {
      target
      title
      url
    }
  }
`;

const SignUp = ({ data: { description, title, link } }) => {
  return (
    <section className="relative overflow-hidden bg-primaryBlue-shade30 py-14 text-accent-blueLightest md:py-24 lg:py-32">
      <div className="absolute top-0 left-0">
        <StaticImage
          src="../../images/decorations/achievments_1.svg"
          className=""
          alt="decoration gradient lines"
          placeholder="tracedSVG"
        />
      </div>
      <div className="absolute bottom-0 right-0 hidden lg:block">
        <StaticImage
          src="../../images/decorations/signup_tablet.png"
          className=""
          alt="ipad point of sale screenshot"
          placeholder="blurred"
        />
      </div>
      <Wrapper className="relative">
        <Title
          title={title}
          description={description}
          className="md:max-w-lg"
          pClassName="text-accent-blueLight"
        />
        {link && (
          <Button
            href={link.url}
            target={link.target}
            className="mt-12 border border-white bg-white/20"
            primary
          >
            {link.title}
          </Button>
        )}
      </Wrapper>
    </section>
  );
};

export default SignUp;
