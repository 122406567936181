import React, { useRef } from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import { AnimatePresence, m } from "framer-motion"

import Wrapper from "../Wrapper/Wrapper"
import Title from "../Title"
import Button from "../Button/Button"
import { useEffect } from "react"

export const fragment = graphql`
  fragment FeaturesSection on WpPage_FrontpageAcf_Content_FeaturesSection {
    description
    fieldGroupName
    title
    features {
      description
      fieldGroupName
      heading
      title
      link {
        target
        title
        url
      }
      image {
        altText
        title
        gatsbyImage(width: 800, quality: 100, placeholder: TRACED_SVG)
      }
    }
  }
`

const Features = ({ data: { title, description, features } }) => {
  const [index, setIndex] = React.useState(0)
  const refs = useRef(null)

  const width = refs?.current?.children[index + 1]?.offsetWidth
  const childPos = refs?.current?.children[index + 1]?.offsetLeft

  const selectedFeature = features[index]
  return (
    <section className="relative overflow-hidden bg-monochromeSky-superLight py-14 md:py-24 lg:py-32">
      <div className="absolute left-0 top-0 hidden h-56 w-56 lg:block">
        <StaticImage
          src={"../../images/decorations/features_squares.svg"}
          className=""
          quality={100}
          placeholder="tracedSVG"
          alt="decoration squares"
        />
      </div>
      <div className="absolute right-0 bottom-1/4 hidden translate-y-1/4 lg:block">
        <StaticImage
          src={"../../images/decorations/features_dots.svg"}
          className=""
          quality={100}
          placeholder="tracedSVG"
          alt="decoration dots"
        />
      </div>
      <Wrapper className="relative">
        <Title title={title} description={description} className="md:mx-auto  md:text-center" />
        <header
          className="no-scrollbar relative mt-9 flex snap-x flex-nowrap justify-between overflow-x-scroll border-b border-monochromeSky duration-300 md:mt-14 lg:mt-20 lg:overflow-x-hidden"
          ref={refs}>
          <div
            className="linear absolute bottom-0 left-0 h-1 w-full translate-x-0 border-b-4 border-monochromeInk-10 underline transition-transform duration-150"
            style={{
              maxWidth: width || `${100 / features.length}%`,
              transform: `translateX(${childPos || 0}px)`,
            }}></div>
          {features.map((feature, i) => (
            <div className={`min-w-[200px] md:flex-1 md:text-center`} key={i}>
              <button
                className={` w-28 snap-center py-4 pl-3 text-left text-lg font-medium md:w-auto lg:text-2xl`}
                onClick={() => setIndex(i)}>
                {feature.title}
              </button>
            </div>
          ))}
        </header>
        <AnimatePresence>
          <m.div
            key={selectedFeature.heading}
            variants={tabAnimation}
            initial="initial"
            animate="enter"
            exit="leave"
            className="mt-6 md:mt-12 md:flex md:flex-row-reverse lg:mt-14">
            <div className="md:ml-12 md:w-full lg:max-w-xl lg:self-center">
              <h3 className="text-2xl font-semibold text-monochromeInk lg:text-5xl">
                {selectedFeature.heading}
              </h3>
              <p className="mt-6 text-sm text-monochromeInk-light lg:mt-12 lg:text-lg">
                {selectedFeature.description}
              </p>
              {selectedFeature.link && (
                <Button
                  href={selectedFeature.link.url}
                  target={selectedFeature.link.target}
                  className="mt-6 text-primaryBlue lg:mt-14">
                  {selectedFeature.link.title}
                </Button>
              )}
            </div>
            <GatsbyImage
              className="mt-6 w-full md:mt-0"
              image={getImage(selectedFeature.image)}
              alt={selectedFeature?.image?.altText || selectedFeature?.image?.title}
            />
          </m.div>
        </AnimatePresence>
      </Wrapper>
    </section>
  )
}

export default Features

const tabAnimation = {
  initial: {
    opacity: 0,
    x: -10,
    transition: {
      delay: 0.2,
    },
  },
  enter: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.3,
      ease: "easeOut",
      delay: 0.2,
    },
  },
  leave: {
    opacity: 0,
    x: 20,
    transition: {
      duration: 0.15,
      ease: "easeIn",
    },
  },
}
