import React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Button from "../Button/Button"
import Wrapper from "../Wrapper/Wrapper"
import Title from "../Title"

export const fragment = graphql`
  fragment IntegrationSection on WpPage_FrontpageAcf_Content_IntegrationSection {
    title
    fieldGroupName
    card {
      description
      title
      icon {
        localFile {
          publicURL
        }
        width
        height
      }
      link {
        target
        title
        url
      }
    }
  }
`

const Integration = ({ data: { title, card } }) => {
  return (
    <section className="relative overflow-hidden bg-monochromeSky-superLight py-14 md:py-24 lg:py-32">
      <div className="absolute right-0 bottom-[5%]  hidden translate-y-1/4 md:block">
        <StaticImage
          src="../../images/decorations/features_dots.svg"
          className=""
          quality={100}
          placeholder="tracedSVG"
          alt="decoration dots"
        />
      </div>
      <div className="absolute top-0 right-0 hidden h-44 w-44 rotate-90 lg:block">
        <StaticImage
          src="../../images/decorations/features_squares.svg"
          className=""
          quality={100}
          placeholder="tracedSVG"
          alt="decoration squares"
        />
      </div>
      <div className="absolute bottom-0 left-0 hidden  lg:block">
        <StaticImage
          src="../../images/decorations/integration_square.svg"
          className=""
          quality={100}
          placeholder="tracedSVG"
          alt="decoration square"
        />
      </div>
      <Wrapper className="relative">
        <Title title={title} className="md:max-w-xl" />
        <div className="mt-9 grid grid-flow-row gap-6 md:mt-14 md:grid-cols-2 lg:mt-20 lg:grid-cols-3 lg:gap-9">
          {card.map((oneCard, index) => (
            <Card key={index} {...oneCard} />
          ))}
        </div>
      </Wrapper>
    </section>
  )
}

export default Integration

const Card = ({ title, description, icon, link }) => {
  return (
    <article className="flex flex-col justify-between bg-white p-4 lg:p-8">
      <div>
        {icon && (
          <img
            src={icon.localFile.publicURL}
            alt={title}
            width={icon.width}
            height={icon.height}
            loading="lazy"
          />
        )}
        {title && (
          <h3 className="mt-9 text-lg font-semibold text-monochromeInk lg:text-2xl">{title}</h3>
        )}
        {description && (
          <p className="mt-4 text-sm text-monochromeInk-light lg:text-base">{description}</p>
        )}
      </div>
      {link && (
        <Button className="mx-0 mt-9 w-max text-primaryBlue" href={link.url} target={link.target}>
          {link.title}
        </Button>
      )}
    </article>
  )
}
