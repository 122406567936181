import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";

import Wrapper from "../Wrapper/Wrapper";
import Title from "../Title";

export const fragment = graphql`
  fragment GeneralFeaturesSection on WpPage_FrontpageAcf_Content_GeneralFeaturesSection {
    description
    fieldGroupName
    title
    features {
      feature
    }
    image {
      gatsbyImage(width: 1920, quality: 100)
      altText
    }
  }
`;

const GeneralFeatures = ({ data: { description, title, features, image } }) => {
  const imagePath = getImage(image);
  return (
    <section className="relative min-h-[920px] overflow-hidden bg-white text-monochromeInk lg:min-h-[800px]">
      <div className="absolute left-0 right-0 bottom-0 h-full max-h-[450px] w-auto  lg:left-0  lg:top-0 lg:max-h-max lg:max-w-[40%]">
        <GatsbyImage
          image={imagePath}
          alt={image?.altText}
          className={`h-full`}
        />
      </div>
      <Wrapper className="pt-14 md:pt-24 lg:grid lg:min-h-[inherit] lg:grid-cols-2 lg:items-center lg:justify-center lg:pt-0">
        <div className=" hidden lg:block lg:w-full"></div>
        <div>
          <Title title={title} description={description} pClassName={`mt-12`} />
          <ul className="mt-9 space-y-6">
            {features &&
              features.map((feature, i) => (
                <li className="flex items-center space-x-3" key={i}>
                  <svg
                    className="h-5 w-5"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      className="fill-primaryBlue"
                      d="M10 .25A9.75 9.75 0 1 0 19.75 10 9.761 9.761 0 0 0 10 .25Zm4.644 8.043-5.5 5.25a.752.752 0 0 1-1.038 0l-2.75-2.625a.75.75 0 0 1 1.038-1.086l2.23 2.13 4.982-4.755a.75.75 0 0 1 1.038 1.086Z"
                      fill="#165FFE"
                    />
                  </svg>
                  <p className="text-sm  text-monochromeInk-light md:text-base  lg:text-lg">
                    {feature?.feature}
                  </p>
                </li>
              ))}
          </ul>
        </div>
      </Wrapper>
    </section>
  );
};

export default GeneralFeatures;
