import React from "react"
import { graphql } from "gatsby"

// Sections
import Achievments from "../components/Home/Achievments"
import Features from "../components/Home/Features"
import Hero from "../components/Home/Hero"
import Integration from "../components/Home/Integration"
import Product from "../components/Home/Product"
import SignUp from "../components/Home/SignUp"
import Testimonials from "../components/Home/Testimonials"
import GeneralFeatures from "../components/Home/GeneralFeatures"

// Components
import Layout from "../components/Layout"
import SEO from "../components/SEO/SEO"

const PageTemplate = ({ data }) => {
  const sections = data?.wpPage?.fields?.content

  return (
    <Layout shiftLayout>
      <SEO page={data.wpPage} />
      {sections?.map((section, index) => {
        switch (section.fieldGroupName) {
          // Hero Section
          case "Page_FrontpageAcf_Content_HeroSection":
            return <Hero data={section} key={index} />

          // Achievments Section
          case "Page_FrontpageAcf_Content_AchievmentsSection":
            return <Achievments data={section} key={index} />

          // Product Section
          case "Page_FrontpageAcf_Content_ProductSection":
            return <Product data={section} key={index} />

          // Features Section
          case "Page_FrontpageAcf_Content_FeaturesSection":
            return <Features data={section} key={index} />

          // Testimonials Section
          case "Page_FrontpageAcf_Content_TestimonialsSection":
            return <Testimonials data={section} key={index} />

          // Integration Section
          case "Page_FrontpageAcf_Content_IntegrationSection":
            return <Integration data={section} key={index} />

          // Sign Up Section
          case "Page_FrontpageAcf_Content_SignUpSection":
            return <SignUp data={section} key={index} />

          case "Page_FrontpageAcf_Content_GeneralFeaturesSection":
            return <GeneralFeatures data={section} key={index} />

          default:
            return <div>This block is not defined</div>
        }
      })}
    </Layout>
  )
}

export default PageTemplate

export const query = graphql`
  query pageQuery($pageTitle: String) {
    wpPage(title: { eq: $pageTitle }) {
      fields: frontPage_ACF {
        content {
          ...HeroSection
          ...AchievmenetsSection
          ...ProductSection
          ...FeaturesSection
          ...TestimonialsSection
          ...IntegrationSection
          ...SignUpSection
          ...GeneralFeaturesSection
        }
      }
      seo {
        ...SEO
      }
    }
  }
`
