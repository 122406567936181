import React from "react"
import useMediaQuery from "./useMediaQuery"

const useViewport = () => {
  const isMobile = useMediaQuery("screen and (max-width: 768px)")
  const isTablet = useMediaQuery("screen and (max-width: 1024px)")
  const isDesktop = useMediaQuery("screen and (min-width: 1024px)")

  return { isMobile, isTablet, isDesktop }
}

export default useViewport
