import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { AnimatePresence, m } from "framer-motion"

// Components
import Wrapper from "../Wrapper/Wrapper"
import Title from "../Title"
import Plus from "../../images/icons/Plus.svg"
import Button from "../Button/Button"

const duration = 0.15

export const fragment = graphql`
  fragment ProductSection on WpPage_FrontpageAcf_Content_ProductSection {
    description
    fieldGroupName
    title
    products {
      description
      title
      link {
        target
        title
        url
      }
      image {
        title
        altText
        gatsbyImage(layout: CONSTRAINED, width: 1400, quality: 100, placeholder: TRACED_SVG)
      }
    }
  }
`

const Product = ({ data }) => {
  const { description, title, products } = data
  const [activeProduct, setActiveProduct] = React.useState(0)

  return (
    <section id="products" className="pt-14 md:pt-24 lg:py-32">
      <Wrapper>
        <Title
          title={title}
          description={description}
          className={`md:max-w-4xl md:text-center lg:mx-auto`}
        />
        <div className="mt-9 border-t border-monochromeSky md:mt-14  lg:mt-20 lg:flex lg:border-none">
          <div className="  flex-1 lg:mr-14 lg:max-w-[50%]">
            {products?.map(
              ({ image, description: prodDescription, title: prodTitle, link }, index) => {
                return (
                  <article
                    key={index}
                    className={`overflow-hidden border-b  border-monochromeSky transition lg:flex lg:overflow-auto
                      ${
                        activeProduct === index
                          ? "lg:border-l-4 lg:border-l-monochromeInk-10 lg:bg-monochromeSky-superLight lg:pl-6"
                          : "lg:pl-8"
                      }
                    `}>
                    <h3 className={`duration-600 flex-1 py-3 text-lg transition lg:py-6`}>
                      <button
                        className="flex w-full items-center justify-between"
                        onClick={() => setActiveProduct(index)}>
                        <span
                          className={`font-medium transition  ${
                            activeProduct === index
                              ? "text-monochromeInk"
                              : "text-monochromeInk-lighter"
                          }`}>
                          {prodTitle}
                        </span>
                        <Plus
                          className={`h-4 w-4 transition duration-500 lg:hidden ${
                            activeProduct === index ? "plus_icon--active" : ""
                          }`}
                        />
                      </button>
                    </h3>
                    <m.div
                      variants={mobileTab}
                      animate={activeProduct === index ? "open" : "hidden"}
                      className={`product_item overflow-hidden  ${
                        activeProduct === index ? "pb-6" : ""
                      }`}>
                      <p className=" text-sm text-monochromeInk-light md:text-base lg:hidden">
                        {prodDescription}
                      </p>
                      {products[activeProduct].link && (
                        <Button
                          href={products[activeProduct].link?.url}
                          target={products[activeProduct].link?.target}
                          className="mt-4  text-primaryBlue lg:hidden">
                          {products[activeProduct].link?.title}
                        </Button>
                      )}
                    </m.div>
                  </article>
                )
              }
            )}
          </div>
          <AnimatePresence exitBeforeEnter>
            <m.div
              key={products[activeProduct].title}
              variants={tabAnimation}
              initial="initial"
              animate="enter"
              exit="leave"
              className="hidden flex-1 overflow-hidden lg:block lg:w-1/2">
              <GatsbyImage
                image={getImage(products[activeProduct].image)}
                alt={products[activeProduct].image.altText || products[activeProduct].image.title}
              />
              <p className="mt-10 text-lg text-monochromeInk-light">
                {products[activeProduct].description}
              </p>
              {products[activeProduct].link && (
                <Button
                  href={products[activeProduct].link?.url}
                  target={products[activeProduct].link?.target}
                  className="mt-10 text-primaryBlue">
                  {products[activeProduct].link?.title}
                </Button>
              )}
            </m.div>
          </AnimatePresence>
        </div>
      </Wrapper>
    </section>
  )
}

export default Product

const tabAnimation = {
  initial: {
    opacity: 0,
    x: "-20px",
    // position: "absolute",
    transition: {
      duration: 0.15,
    },
  },
  enter: {
    x: 0,
    position: "relative",
    opacity: 1,
    transition: {
      type: "tween",
      duration: 0.2,
    },
  },
  leave: {
    x: "20px",
    opacity: 0,
    transition: {
      duration: 0.15,
      type: "tween",
    },
  },
}

const mobileTab = {
  open: {
    opacity: 1,
    maxHeight: "50vh",
    y: 0,
    transition: {
      duration: 0.6,
      maxHeight: {
        duration: 0.5,
        type: "tween",
      },
    },
  },
  hidden: {
    opacity: 0,
    y: 50,
    maxHeight: "0vh",
    transition: {
      maxHeight: {
        duration: 0.5,
        type: "tween",
      },
    },
  },
}
