import React from "react"
import { getImage, StaticImage, GatsbyImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"

// Components
import Wrapper from "../Wrapper/Wrapper"
import Button from "../Button/Button"

export const fragment = graphql`
  fragment HeroSection on WpPage_FrontpageAcf_Content_HeroSection {
    description
    fieldGroupName
    title
    tag
    buttonOne {
      target
      title
      url
    }
    buttonTwo {
      target
      title
      url
    }
    bgImage {
      gatsbyImage(quality: 100, width: 1920)
    }
  }
`

const Hero = ({ data }) => {
  const { buttonOne, buttonTwo, tag } = data
  return (
    <section className="relative bg-monochromeInk-10 pt-28 text-monochromeSky-lightest lg:min-h-[800px] lg:pt-64">
      <Wrapper className="relative z-10">
        <div className="max-w-lg lg:max-w-2xl">
          {tag && <h2 className="mb-4 text-xl font-semibold text-accent-yellow">{tag}</h2>}
          <h1 className="text-3xl font-semibold   md:text-5xl lg:text-6xl">{data.title}</h1>
          <p className={`mt-6 text-sm md:text-lg lg:text-xl`}>{data.description}</p>
          <div className="mt-9 flex flex-col sm:flex-row">
            {buttonOne && (
              <Button primary href={buttonOne.url} target={buttonOne.target}>
                {buttonOne.title}
              </Button>
            )}
            {buttonTwo && (
              <Button
                className={`mx-auto mt-9 text-white after:bottom-0 sm:mx-0 sm:mt-0 sm:ml-9`}
                href={buttonTwo.url}
                target={buttonTwo.target}>
                {buttonTwo.title}
              </Button>
            )}
          </div>
        </div>
      </Wrapper>
      <div className="relative mt-14 h-full w-full lg:absolute lg:inset-y-0 lg:right-0 lg:mt-0 lg:max-w-4xl">
        <GatsbyImage
          image={getImage(data.bgImage.gatsbyImage)}
          className="min-h-[300px] lg:mt-0 lg:h-full"
          layout="fullWidth"
          loading="eager"
          quality={100}
          alt="dark gray background"
        />
        <div className="gradient absolute inset-0 -ml-1 h-1/2 w-full bg-gradient-to-b from-monochromeInk-10 to-transparent lg:h-full lg:bg-gradient-to-r"></div>
      </div>
    </section>
  )
}

export default Hero
