import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import Wrapper from "../Wrapper/Wrapper";
import Button from "../Button/Button";
import Title from "../Title";
import IconList from "../IconList";

export const fragment = graphql`
  fragment AchievmenetsSection on WpPage_FrontpageAcf_Content_AchievmentsSection {
    description
    fieldGroupName
    title
    companies {
      companyLogo {
        logo {
          width
          altText
          height
          localFile {
            publicURL
          }
          title
          id
        }
      }
      title
    }
    button {
      target
      title
      url
    }
    achievmenets {
      fieldGroupName
      number
      title
      titleStrongText
      icons
    }
  }
`;

const Achievments = ({ data }) => {
  const { button } = data;
  return (
    <section className="relative overflow-hidden bg-primaryBlue-shade10 py-14 text-accent-blueLightest md:py-24 lg:py-32">
      <div className="absolute top-0 left-0">
        <StaticImage
          src={"../../images/decorations/achievments_1_unoptimized.svg"}
          className=""
          alt="decoration"
          placeholder="tracedSVG"
        />
      </div>
      <div className="absolute  right-0 -mr-8 hidden md:bottom-1/2 md:block md:translate-y-1/2 lg:bottom-0  lg:translate-y-0 ">
        <StaticImage
          src={"../../images/decorations/achievments_2_unoptimized.svg"}
          className=""
          alt="decoration"
          placeholder="tracedSVG"
        />
      </div>

      <Wrapper className="relative lg:grid lg:grid-flow-col lg:grid-cols-12">
        <div className="space-y-8 md:space-y-20 lg:col-start-8 lg:col-end-12 lg:gap-6">
          {data?.achievmenets?.map(
            ({ number, titleStrongText, title, icons }, index) => (
              <article key={index}>
                <h3 className="text-5xl font-semibold text-accent-blueLightest md:text-7xl">
                  {number}
                </h3>
                <p className="mt-5 text-sm md:text-2xl">
                  <IconList
                    icon={icons}
                    className="mr-2 inline h-5 w-5 md:h-8 md:w-8"
                  />
                  <strong>{titleStrongText}</strong> {title}
                </p>
              </article>
            )
          )}
        </div>
        <div className="mt-12 lg:col-start-1 lg:col-end-6 lg:mt-0">
          <Title
            title={data.title}
            description={data.description}
            hClassName={`max-w-xl lg:max-w-none`}
            pClassName="text-accent-blueLight"
          />
          {button && (
            <Button
              className="boder-white mt-9 border bg-white/20 text-center"
              primary
              href={button.url}
              target={button.target}
            >
              {button.title}
            </Button>
          )}
          {data.companies && (
            <>
              <p className="mt-12 text-center text-sm font-medium md:text-left md:text-lg lg:text-xl  ">
                {data?.companies?.title}
              </p>
              <div className="mx-auto flex w-full flex-wrap items-start justify-center space-x-6 sm:space-x-8  md:mx-0 md:justify-start ">
                {data?.companies?.companyLogo?.map((logo) => {
                  return (
                    <img
                      className="mt-6 h-6 sm:h-8"
                      loading="lazy"
                      key={logo.logo.id}
                      src={logo.logo.localFile.publicURL}
                      width={logo.logo.width}
                      height={logo.logo.height}
                      alt={logo.logo.altText || logo.logo.title}
                    />
                  );
                })}
              </div>
            </>
          )}
        </div>
      </Wrapper>
    </section>
  );
};

export default Achievments;
