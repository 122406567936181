import React from "react"

// icons
import Globe from "../images/icons/Globe.svg"
import Receipt from "../images/icons/Receipt.svg"
import TrendUp from "../images/icons/TrendUp.svg"

const IconList = ({ icon, className = "mr-4 flex-shrink-0 fill-current transition" }) => {
  switch (icon) {
    case "globe":
      return <Globe className={className} />
    case "receipt":
      return <Receipt className={className} />
    case "trendUp":
      return <TrendUp className={className} />

    default:
      return <Globe className={className} />
  }
}

export default IconList
